import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import Subscribe from "../../components/subscribe"
import { StaticImage } from "gatsby-plugin-image"

const diversityPage = () => (
    <Layout siteTitle={"Why Diversity Matters"} >

    {/* header section */}
    <div className="relative bg-indigo-800 border-b-4 border-indigo-700">
      <div className="absolute inset-0">
        <StaticImage
          className="h-full w-full object-cover"
          src="../../images/WDMHeader.jpg"
          alt="Why Diversity Matters header"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-indigo-700 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
      <div className="text-center">
          <p className="mt-1 text-4xl font-bold tracking-tight text-indigo-700 sm:text-5xl lg:text-6xl">
            Why Diversity Matters
          </p>
          <p className="mx-auto font-light mt-5 max-w-xl text-xl text-white">
          Neurological disorders are on the rise and are increasingly recognized as major causes of death and disability worldwide. These disorders do not discriminate between gender or race; they can affect anyone.
          </p>
        </div>
      </div>
    </div>

  {/* women in neuroscience */}
  <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">Women in Neuroscience</h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="!aspect-w-12 !aspect-h-7 lg:!aspect-none">
                  <StaticImage
                    className="rounded-lg object-cover object-center shadow-lg"
                    src="../../images/intern1.jpg"
                    alt="Intern in lab"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-500">
              The neuroscience field suffers from a lack of diverse representation in practitioners, researchers, and leaders. These disparities are due to barriers to underrepresented minorities entering science, and biases and stereotypes also are factors. This lack of diversity leads to inequitable outcomes among <Link to="/">women</Link> and people of color.<br /><br />
              </p>
            </div>


            {/* pull quote */}
            <section className="overflow-hidden bg-white">
                    <div className="relative mx-auto max-w-7xl px-4 pt-20 pb-12 sm:px-6 lg:px-8 lg:py-20">
                      <svg
                        className="absolute top-full left-0 translate-x-80 -translate-y-24 transform lg:hidden"
                        width={784}
                        height={404}
                        fill="none"
                        viewBox="0 0 784 404"
                        aria-hidden="true"
                      >
                        <defs>
                          <pattern
                            id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                          >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                          </pattern>
                        </defs>
                        <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
                      </svg>

                      <div className="relative lg:flex lg:items-center">

                        <div className="relative lg:ml-10">
                          <svg
                            className="absolute top-0 left-0 h-36 w-36 -translate-x-8 -translate-y-24 transform text-indigo-200 opacity-50"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 144 144"
                            aria-hidden="true"
                          >
                            <path
                              strokeWidth={2}
                              d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                            />
                          </svg>
                          <blockquote className="[border-left-width:0] relative">
                            <div className="text-2xl font-medium leading-9 text-gray-900">
                              <p>
                              Women make up at least 50% of neuroscience students at the predoctoral and doctoral levels, but they leave the field at a greater rate than men. 
                              </p>
                            </div>
                          </blockquote>
                        </div>
                      </div>
                    </div>
            </section>

            <div className="prose text-lg prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <p>
              <b>Women who remain in the neuroscience field are:</b>
              </p>
              <ul>
                <li>Less represented in research, at leadership levels, and in tenured professorships</li>
                <li>Paid less</li>
                <li>Receive fewer primary citations</li>
                <li>Have their work questioned more</li>
                <li>Do not always receive credit for their work</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div className="bg-white pb-24 lg:pt-14 lg:pb-38">
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        <div className="relative overflow-hidden rounded-xl bg-indigo-300 py-24 px-8 shadow-2xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-16">
          <div className="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
            <StaticImage
              src="../../images/WomanInLab.jpg"
              alt=""
              className="h-full w-full object-cover"
            />
          </div>
          <div className="relative lg:col-span-1">
            <blockquote className="mt-6 text-white">
            <h3 className="mt-2 text-3xl text-black font-bold leading-8 tracking-tight sm:text-4xl">People of Color in Neuroscience</h3>
              <footer className="mt-6">
                <p className="flex flex-col font-medium">
                Despite roughly equal levels of interest in STEM, students from underrepresented minorities are about half as likely to complete a STEM degree in college, as compared to white and Asian students.<br /><br />This results in a limited pool of talent from which the field can recruit and hampers the ability to highlight the research and work of people from underrepresented backgrounds. 

                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <div className="relative bg-gradient-to-r from-indigo-800 to-indigo-700">
      <div className="relative h-80 overflow-hidden md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
        <StaticImage
          className="h-full w-full object-cover"
          src="../../images/DiversityInLab.jpg"
          alt="Lab workers working with various tests"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 926 676"
          aria-hidden="true"
          className="absolute left-24 -bottom-24 w-[57.875rem] transform-gpu blur-[118px]"
        >
          <path
            fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
            fillOpacity=".4"
            d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
          />
          <defs>
            <linearGradient
              id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
              x1="926.392"
              x2="-109.635"
              y1=".176"
              y2="321.024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:py-40 lg:px-8">
        <div className="pr-6 pl-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
          <p className="mt-2 text-4xl font-bold tracking-tight text-black">Benefits of Diversity</p>
          <div className="prose text-lg prose-indigo mx-auto mt-5 text-white lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <p>
              <b>Research shows that diversity in practitioners, researchers and leaders has real and lasting impact by:</b>
              </p>
              <ul className="marker:text-white">
                <li>Accelerating breakthroughs</li>
                <li>Reducing barriers faced by underrepresented minorities throughout society</li>
                <li>Improving research and health outcomes across all populations</li>
              </ul>
            </div>
        </div>
      </div>
    </div>

    <div className="overflow-hidden bg-gradient-to-b from-gray-50 to-white py-16">
      <div className="mx-auto max-w-7xl space-y-8 px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-base lg:max-w-none">
          <p className="mt-2 text-3xl z-50 font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          Studies show that diverse groups, including women and people of color, generate more creative ideas and improved scientific outcomes than homogenous groups. 
          </p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-8">
          <div className="relative z-10">
            <div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
              <p>
              Other benefits of diversity include:
              </p>
              <ul>
                <li>Increased trust from minority groups who have historically been marginalized and even oppressed by medical research, leading to better recruitment from these populations to conduct studies on the issues they face</li>
                <li>Increased focus on healthcare disparities, as underrepresented minority scientists often have a greater drive to reduce such disparities as well as a better understanding of how they arise</li>
                <li>A wider range of patients see medical professionals who look like them, and patients who identify with their physician may better adhere to treatment and have better outcomes</li>
                <li>When given a choice, racial and ethnic minority patients are more likely to select physicians who share their racial/ethnic backgrounds and report greater satisfaction and receiving higher quality care</li>
              </ul>
            </div>

          </div>
          <div className="relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none">
            <svg
              className="absolute pt-12 top-0 right-0 -mt-20 -mr-20 lg:top-auto md:pt-40 lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={15} width={4} height={4} className="text-gray-200 pt-6 z-0" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
            </svg>
            <blockquote className="relative rounded-lg bg-white shadow-lg">
              <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                <div className="relative mt-8 text-lg font-medium text-gray-700">
                  <svg
                    className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-gray-200"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">
                  Prejudice is a burden that confuses the past, threatens the future and renders the present inaccessible.
                  </p>
                </div>
              </div>
              <cite className="relative flex items-center rounded-b-lg bg-indigo-600 py-5 px-6 not-italic sm:mt-10 sm:items-start sm:py-5 sm:pl-12 sm:pr-10">
                <span className="relative flex-none rounded-full border-2 border-white sm:absolute sm:top-0 sm:-translate-y-1/2 sm:transform">
                  <StaticImage
                    className="h-12 w-12 rounded-full bg-indigo-300 sm:h-20 sm:w-20"
                    src="../../images/MayaAngelou.jpg"
                    alt=""
                  />
                </span>
                <span className="relative ml-4 font-semibold leading-6 text-indigo-700 sm:ml-24 sm:pl-1">
                  <span className="font-semibold text-white sm:inline">Maya Angelou</span>{' '}
                  <span className="sm:inline">Author, poet, and civil rights activist</span>
                </span>
              </cite>
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    {/* email subscribe */}
    <Subscribe />

    </Layout>
)

export const Head = () => <Seo title="Why Diversity Matters" />

export default diversityPage